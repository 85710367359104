import { operations, paths } from "./generated-types";
import createClient from "openapi-fetch";

const client = createClient<paths>({
  baseUrl: import.meta.env.VITE_APP_API_BASE_URI,
  credentials: "include",
});

export const register = (
  body: operations["candidate.write.register"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST("/v1/candidate.write/register", { body });
};

export const login = (
  body: operations["user.write.login"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST("/v1/user.write/login", { body });
};

export const getSelf = (
  query: operations["user.read.self"]["parameters"]["query"],
) => {
  return client.GET("/v1/user.read/self", { params: { query } });
};

export const listApplicationProcessesOpenForEnrolment = (
  query: operations["applicationprocess.read.listopenforenrolment"]["parameters"]["query"],
) => {
  return client.GET("/v1/applicationprocess.read/listopenforenrolment", {
    params: { query },
  });
};

export const getCandidate = (
  query: operations["candidate.read.get"]["parameters"]["query"],
) => {
  return client.GET("/v1/candidate.read/get", {
    params: { query },
  });
};

export const verifyEmail = (
  body: operations["user.write.verifyemail"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST("/v1/user.write/verifyemail", { body });
};

export const listCandidates = (
  query: operations["candidate.read.list"]["parameters"]["query"],
) => {
  return client.GET("/v1/candidate.read/list", {
    params: { query },
  });
};

export const enrolToApplicationProcess = (
  body: operations["participationinapplicationprocess.write.enrol"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST("/v1/participationinapplicationprocess.write/enrol", {
    body,
  });
};

export const listParticipationInApplicationProcessByApplicationProcessIdAndFieldOfStudyId =
  (
    query: operations["participationinapplicationprocess.read.listbyapplicationprocessidandfieldofstudyid"]["parameters"]["query"],
  ) => {
    return client.GET(
      "/v1/participationinapplicationprocess.read/listbyapplicationprocessidandfieldofstudyid",
      {
        params: { query },
      },
    );
  };

export const listParticipationInApplicationProcessByCandidateId = (
  query: operations["participationinapplicationprocess.read.listbycandidateid"]["parameters"]["query"],
) => {
  return client.GET(
    "/v1/participationinapplicationprocess.read/listbycandidateid",
    {
      params: { query },
    },
  );
};

export const getApplicationProcess = (
  query: operations["applicationprocess.read.get"]["parameters"]["query"],
) => {
  return client.GET("/v1/applicationprocess.read/get", {
    params: { query },
  });
};

export const listApplicationProcessesByCommitteeMember = (
  query: operations["applicationprocess.read.listbycommitteemember"]["parameters"]["query"],
) => {
  return client.GET("/v1/applicationprocess.read/listbycommitteemember", {
    params: { query },
  });
};

export const getDocumentUploadLink = (
  query: operations["participationinapplicationprocess.read.getdocumentuploadlink"]["parameters"]["query"],
) => {
  return client.GET(
    "/v1/participationinapplicationprocess.read/getdocumentuploadlink",
    {
      params: { query },
    },
  );
};

export const getHomeworkUploadLink = (
  query: operations["participationinapplicationprocess.read.gethomeworkuploadlink"]["parameters"]["query"],
) => {
  return client.GET(
    "/v1/participationinapplicationprocess.read/gethomeworkuploadlink",
    {
      params: { query },
    },
  );
};

export const getEntranceExamUploadLink = (
  query: operations["participationinapplicationprocess.read.getentranceexamuploadlink"]["parameters"]["query"],
) => {
  return client.GET(
    "/v1/participationinapplicationprocess.read/getentranceexamuploadlink",
    {
      params: { query },
    },
  );
};

export const setEntranceExamResult = (
  body: operations["participationinapplicationprocess.write.setentranceexamresult"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST(
    "/v1/participationinapplicationprocess.write/setentranceexamresult",
    {
      body,
    },
  );
};

export const setWorkSampleAsAnalogoue = (
  body: operations["participationinapplicationprocess.write.setworksampleasanalogoue"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST(
    "/v1/participationinapplicationprocess.write/setWorkSampleAsAnalogoue",
    {
      body,
    },
  );
};

export const setEntranceExamAssessment = (
  body: operations["participationinapplicationprocess.write.setentranceexamassessment"]["requestBody"]["content"]["application/json"],
) => {
  return client.POST(
    "/v1/participationinapplicationprocess.write/setentranceexamassessment",
    {
      body,
    },
  );
};

export const csvDownloadPath = (applicationProcessId: string) =>
  `${import.meta.env.VITE_APP_API_BASE_URI}/v1/participationinapplicationprocess.read/export.xlsx?applicationProcessId=${applicationProcessId}`;
