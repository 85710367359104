import { useContext } from "react";
import { UserContext } from "../UserContext.tsx";
import { useQuery } from "@tanstack/react-query";
import { getSelf } from "../openapi/api-client.ts";

export const useLoggedInUser = () => {
  const userIdContext = useContext(UserContext);

  return useQuery({
    queryKey: ["self", { userId: userIdContext.userData.userId }],
    queryFn: async () => {
      const response = await getSelf(undefined);

      if (!response.data) {
        throw new Error("no data given");
      }

      return response.data;
    },
    retry: 1,
    enabled: !!userIdContext.userData.userId,
    staleTime: 60 * 1000,
  });
};
